const useAPI = () => {
  const urlBase = "https://api.sendingup.co/";
  const urlAPI = urlBase; //# Producción

  // API_1
  //const urlAPI_1 = urlBase + "prod/api1/" //"https://106ul0a45f.execute-api.us-east-1.amazonaws.com/prod/"//urlBase + "api_a/"; //# Producción API_1
  const urlAPI_1 = "https://tvd6p8m4xk.execute-api.us-east-1.amazonaws.com/dev/"//urlBase + "api_a/"; //# Producción API_1
  // const urlAPI_1 = "http://localhost:3000/dev/"; // pruebas


  // API_2
  //const urlAPI_2 = urlBase + "prod/api2/" //"https://wlea75oglc.execute-api.us-east-1.amazonaws.com/prod/" //urlBase + "api_b/"; //# Producción API_2
  const urlAPI_2 = "https://jjd1bv4x70.execute-api.us-east-1.amazonaws.com/dev/" //urlBase + "api_b/"; //# Producción API_2
  // const urlAPI_2 = "http://localhost:3001/dev/"; // pruebas

  const urlSocket = "wss://ffmqisvqwh.execute-api.us-east-1.amazonaws.com/dev/";
  //const urlSocket = "wss://b01jbyxtga.execute-api.us-east-1.amazonaws.com/production/";
  const token = localStorage.getItem("USER_TOKEN");

  const daysOverdue = 8;
  const name_version_app = "Nova";
  const number_version_app = "2.4";

  const optGET = () => {
    const optionsGET = {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    return optionsGET;
  };

  const optPOST = (values) => {
    const optionsPOST = {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(values),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    return optionsPOST;
  };

  const optPUT = (values) => {
    const optionsPUT = {
      method: "PUT",
      mode: "cors",
      body: JSON.stringify(values),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    return optionsPUT;
  };

  const optDELETE = () => {
    const optionsDELETE = {
      method: "DELETE",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    return optionsDELETE;
  };

  return {
    urlAPI,
    urlAPI_1,
    urlAPI_2,
    optGET,
    optPOST,
    optPUT,
    optDELETE,
    daysOverdue,
    urlSocket,
    name_version_app,
    number_version_app
  };
};

export default useAPI;
